import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Link } from "react-router-dom";

import CustomizedButton from '../shared/components/customizedButton';
import CustomInput from '../shared/components/CustomInput';
import { markFav } from 'shared/planner/actions';
import { login, bootstrap } from 'shared/auth/actions';
import GoogleSignUp from './register/components/googleSignUp';
import MicrosoftSignUp from './register/components/microsoftSignUp';

import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { googleSignUp } from "shared/googleSignUp/actions";
import { microsoftSignUp } from "shared/microsoftSignUp/actions";


const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email!')
    .required('*Email is required'),
  password: Yup.string()
    .required('*Password is required'),
});
class Login extends Component {

  static propTypes = {
    login: PropTypes.func.isRequired,
    bootstrap: PropTypes.func.isRequired,
    googleSignUp: PropTypes.func.isRequired,
    microsoftSignUp: PropTypes.func.isRequired
  };
  state = {
    passwordTypePass: 'password',
  }
  changePasswordFieldType = () => {
    if (this.state.passwordTypePass === 'password') {
      this.setState({ passwordTypePass: 'text' })
    }
    else {

      this.setState({ passwordTypePass: 'password' })
    }
  }
  onClick = () => {

    this.props.googleSignUp().then((response) => {
      window.location.assign(response.googleUrlDetails.data.auth_url)

    })
  }

  microsoftSignClick = () => {
    this.props.microsoftSignUp().then((response) => {
      window.location.assign(response.urlDetails.data.auth_url);
    })
  }



  handleSubmit = (values, { setSubmitting }) => {
    let extensionPopup=localStorage.getItem('disablePopup')
    let selectedDate=localStorage.getItem('selectedDate')
   let redirectPath= localStorage.getItem('redirect_path')
    localStorage.clear()
    localStorage.setItem('disablePopup',extensionPopup)
    if(redirectPath!==null&&redirectPath!==undefined)
    {
    localStorage.setItem('redirect_path',redirectPath)
    }
    if(selectedDate!==null&&selectedDate!==undefined)
    {
    localStorage.setItem('selectedDate',selectedDate)
    }
    return this.props.login(values)
      .then((response) => {

        // if(response.person.profilePhoto)
        // {
        //   var c = document.getElementById("userCanvas");
        //   var ctx = c.getContext("2d");

        //   var img = document.getElementById("userPhoto");

        //  img.src=response.person.profilePhoto && response.person.profilePhoto.path;



        //      img.onload=function(){
        //       alert("load")
        //       // img.crossorigin="anonymous";
        //       c.width=img.width;
        //       c.height=img.height;
        //       ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);

        //        }






        // }

        this.props.bootstrap()
          .then(() => {

            localStorage.setItem('type', response.person.type);
            setTimeout(function () {
              const redirectPath = localStorage.getItem('redirect_path')
              const widget = localStorage.getItem('widget_path')
              //localStorage.removeItem('redirect_path');
              if (localStorage.getItem('widgetLogin') === 'true') {
                localStorage.removeItem('widgetLogin');
                localStorage.removeItem('widget_path');

                this.props.history.push(widget);
              }
              else if (response.person.type === 1) {
                if (localStorage.getItem('fhtoken') === null) {
                  this.props.history.push('/profileInfoConfirm?edit=1')
                }
                else if (redirectPath !== null) {
                  //this.props.history.push(redirectPath)
                  window.location.href = redirectPath;
                }
                else {
                  window.location.href = './myVenue'
                }
              }
              else if (response.person.type === 0) {
                if (localStorage.getItem('fhtoken') === null) {
                  this.props.history.push('/profileInfoConfirm?edit=1')
                }
                else if (redirectPath !== null) {
                  //this.props.history.push(redirectPath)
                  window.location.href = redirectPath;
                }
                else if (localStorage.getItem('venueId') !== null && localStorage.getItem('venueId') !== '') {
                  this.props.markFav(JSON.parse(localStorage.getItem('venueId')))
                    .then((response) => {
                      let url = localStorage.getItem('location');
                      localStorage.setItem('venueId', '')
                      localStorage.setItem('location', '')


                      window.location.href = url;


                    })
                }
                else {
                  window.location.href = './browseVenue'
                }

              }
            }.bind(this), 500);
          })
          .catch(error => {
            // if(localStorage.getItem('type') == 0){
            this.props.history.push('/profileInfoConfirm')
            // }
          })
      })
      .catch(error => {
        setSubmitting(false);
      });
  };

  render() {

    return (
      <div>
        {/* 
<img id="userPhoto" alt="The Scream" crossOrigin="anonymous"/>
<canvas id="userCanvas"></canvas> */}

        <div className="login">
          <Grid className="signup__inner" container justify="center">
            <Grid item xl={3} xs={10} md={4} lg={4} sm={6}>
              {/* <h3 className="firstHold" align="center">FIRST-HOLD has been added to Chrome!</h3> */}
              <div className="signupForm">
                <h1 align="center" className="createAnAccount">LOGIN</h1>
                <Grid container justify="center" className="login-btn-ui">
                  {/* <div>
                    {this.renderSSO()}
                import { connect } from 'react-redux';
  import { bindActionCreators } from 'redux';    <hr />
                  </div> */}

                  <GoogleSignUp className="login-btn-block" text="LOGIN WITH GOOGLE" onClick={this.onClick} />
                  <MicrosoftSignUp text="LOGIN WITH MICROSOFT" onMicrosoftClick={this.microsoftSignClick} />
                </Grid>
                <div className="yourBusiness" align="center">
                  Your business e-mail used for scheduling
                </div>
                <Grid>
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={LoginSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="formFields">
                        <Grid container justify="center">
                          <Grid item sm={12} xs={12}>
                            <label className="emailLabel">
                              Email Address
                            </label>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field className="emailField" align="center" type="text" name="email" placeholder="Enter Email Address" />
                            <div className="error"><ErrorMessage name="email" /></div>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <label className="passwordLabel">
                              Password
                            </label>
                          </Grid>
                          <Grid item sm={12} xs={12}>

                            <div className="password_field_div" style={{ position: "relative" }}>
                              <CustomInput st='basicinfo' type={this.state.passwordTypePass} name="password" placeholder="Enter password" passwordField changePasswordFieldType={this.changePasswordFieldType} />
                            </div>
                            {/* <Field className="passwordField" type="password" name="password" placeholder="Enter Password" />
                          <div className="error"><ErrorMessage name="password" /></div> */}
                          </Grid>
                          <Grid item sm={12} className="forgotPassword">
                            <Link to="/forgotPassword" >
                              Forgot Password?
                            </Link>
                          </Grid>

                        </Grid>

                        <div className="signupDiv">
                          <CustomizedButton label="Login" disabled={isSubmitting} type="submit" />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </div>
              <div className="already" align="center">New user? <Link className="signinLink" to='/register'>Create Account</Link>.</div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state.data.auth)
//   return {
//     type: state.data.auth.type,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
      ...bindActionCreators({ login,bootstrap,googleSignUp,microsoftSignUp,markFav}, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Login);
